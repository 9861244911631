<template>
  <div>
    <v-tour
      :options="tourOptions"
      :steps="steps"
      name="memberTour"
      style="background-color: #ffffff; color: #0067ce"
    ></v-tour>

    <div class="members">
      <v-row v-if="!isInfoLoaded && canManageInvite" flat no-gutters>
        <v-col class="mb-4 text-right">
          <v-row align="center" justify="end">
            <v-col cols="auto">
              <v-btn
                id="downloadUDID"
                class="text-transform-none"
                color="primary"
                outlined
                @click="downloadAllUDID"
              >
                <v-icon left>mdi-download</v-icon>
                Download iOS UDIDs
              </v-btn>
              <v-btn icon small @click="openUDIDHelpModal">
                <v-icon size="20">mdi-help-circle-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div v-if="!isInfoLoaded && canViewTeamMembers">
        <v-skeleton-loader
          v-if="loading"
          class="transparent"
          type="table-thead, table-tbody"
        />
        <template v-else>
          <DatatableWrapper
            :loading="loading"
            :pagination="pagination"
            @onChange="handleOnChange"
          >
            <v-data-table
              :headers="headers"
              :items="allMembers"
              mobile-breakpoint="0"
              hide-default-footer
            >
              <template #item.avatar="{ item }">
                <ProfileImage
                  :alt="item.name"
                  :image="item.image"
                  class="my-1"
                />
              </template>

              <template #item.name="{ item }">
                <b v-if="loggedUser == item.user_id"> You </b>
                <b v-else>{{ item.name }}</b>
                <div>
                  {{ item.email }}
                </div>
              </template>
              <template #item.role="{ item }">
                {{ get(item, "role.name", "") }}
              </template>
              <template #item.apps="{ item }">
                <v-btn @click="showApps(item)" icon>
                  <b class="primary--text">
                    {{ get(item, "stats.apps", "") }}
                  </b>
                </v-btn>
              </template>
              <template #item.devices="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="showDevice(item)" icon v-on="on">
                      <b class="primary--text" v-on="on">
                        {{
                          (item?.stats?.devices?.ios || 0) +
                          (item?.stats?.devices?.android || 0)
                        }}
                      </b>
                    </v-btn>
                  </template>
                  <ul>
                    <li>Android: {{ item?.stats?.devices?.android || 0 }}</li>
                    <li>iOS: {{ item?.stats?.devices?.ios || 0 }}</li>
                  </ul>
                </v-tooltip>
              </template>
              <template #item.status="{ item }">
                <span
                  :class="item.status == 1 ? 'success--text' : 'error--text'"
                >
                  {{ item.status == 1 ? "Active" : "Inactive" }}
                </span>
              </template>
              <template #item.last_activity="{ item }">
                <div v-if="item.last_activity">
                  <vue-hoverable-date :date="item.last_activity" />
                </div>
                <div v-else>N/A</div>
              </template>
              <template #item.join="{ item }">
                <vue-hoverable-date :date="item.created_at" />
              </template>

              <template #item.action="{ item }">
                <action-dropdown
                  v-if="!getInactiveUserMessage && !getIsUserDisabled"
                >
                  <template #list>
                    <v-list-item
                      v-if="
                        canManageTeam &&
                        item.role.name !== 'Owner' &&
                        loggedUser !== item.user_id &&
                        loggedUserRole === 'Owner' &&
                        canManageTeam &&
                        item.status == 1
                      "
                      @click="openTransferOwnerModal(item)"
                    >
                      <v-icon small v-text="'mdi-account-switch'"></v-icon>
                      Transfer ownership
                    </v-list-item>
                    <v-list-item
                      v-if="
                        loggedUser !== item.user_id &&
                        item.status == 2 &&
                        canManageTeam
                      "
                      @click="openUserDeactiveModal(item)"
                    >
                      <v-icon
                        small
                        v-text="
                          item.status == 1
                            ? 'mdi-account-remove'
                            : 'mdi-account-check'
                        "
                      >
                      </v-icon>
                      {{ item.status == 1 ? "Deactivate" : "Activate" }}
                    </v-list-item>
                    <v-list-item
                      v-if="
                        loggedUser !== item.user_id &&
                        item.status == 1 &&
                        canManageTeam
                      "
                      @click="setCurrentMemberAndUpdate(item)"
                    >
                      <v-icon small>edit</v-icon>
                      edit
                    </v-list-item>
                    <v-list-item
                      v-if="canManageTeamMembers"
                      @click="showDevice(item)"
                    >
                      <v-icon left small>devices</v-icon>
                      Devices
                    </v-list-item>
                    <v-list-item
                      v-if="loggedUser === item.user_id || canManageTeam"
                      id="showAppBtnMember"
                      @click="showApps(item)"
                    >
                      <v-icon left small>apps</v-icon>
                      Apps
                    </v-list-item>
                    <!-- <v-list-item
                      v-if="
                        loggedUser !== item.user_id &&
                        item.status == 1 &&
                        canManageTeam
                      "
                      @click="openUserDeactiveModal(item)"
                    >
                      <v-icon
                        small
                        v-text="
                          item.status == 1
                            ? 'mdi-account-remove'
                            : 'mdi-account-check'
                        "
                        >cancel
                      </v-icon>
                      {{ item.status == 1 ? "Deactivate" : "Activate" }}
                    </v-list-item> -->
                    <v-list-item
                      v-if="loggedUser !== item.user_id && canManageTeam"
                      @click="setCurrentMemberAndRemove(item)"
                    >
                      <v-icon small>cancel</v-icon>
                      remove
                    </v-list-item>
                  </template>
                </action-dropdown>
              </template>

              <template #footer.page-text>
                <div>
                  <v-btn
                    class="text-transform-none"
                    color="darkGrey"
                    text
                    @click="checkMember()"
                  >
                    <v-icon
                      :color="$vuetify.theme.dark ? '' : 'grey'"
                      left
                      v-text="'mdi-reload'"
                    >
                    </v-icon>
                    Reload
                  </v-btn>
                  Total: <b>{{ getTotalMembersCount }}</b>
                </div>
              </template>
            </v-data-table>
          </DatatableWrapper>

          <SidePanel v-model="showDeviceInfo" width="600">
            <DeviceInfo
              :busy="busy"
              :devices="devices"
              :loading="drawerLoading"
              :member="member"
              @download-udid="downloadMembersUDID"
              @download-udid-info="openUDIDHelpModal"
            />
          </SidePanel>

          <SidePanel v-model="showAppInfo">
            <AppsInfo
              v-if="showAppInfo"
              :current-member="currentMember.user_id"
              :loading="drawerLoading"
              :logged-user="loggedUser"
              :member="memberDetails"
              :modals-apps="appInfo"
              :selected-member="selectedMember"
              @close="showAppInfo = false"
              @refresh="showApps"
            />
          </SidePanel>

          <Modal v-model="dialog" @close="dialog = false">
            <template #card>
              <v-card v-if="dialog">
                <v-card-title class="bold-text">Remove member</v-card-title>
                <v-divider></v-divider>
                <v-card-text class="my-4 message">
                  <p>
                    Are you sure you want to remove
                    <b>{{ currentMember.name }}</b> from this team?
                  </p>
                </v-card-text>
                <v-divider />
                <v-card-actions class="py-3">
                  <div>No undo for this action.</div>
                  <v-spacer />
                  <v-btn
                    class="text-transform-none"
                    color="primary"
                    text
                    @click="cancelRemoveMember"
                    >No
                  </v-btn>
                  <v-btn
                    :loading="removeLoader"
                    :outlined="$vuetify.theme.dark"
                    class="text-transform-none"
                    color="primary"
                    @click="removeMember"
                  >
                    Yes
                  </v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </Modal>

          <SidePanel
            v-model="drawer"
            :loading="updateLoading"
            @close="drawer = false"
          >
            <!--  -->
            <h1 class="text-center text-capitalize display-1 mb-15">edit</h1>
            <h3 v-if="currentMember" class="col-12 text-center">
              {{ currentMember.name }}
            </h3>
            <v-divider class="mb-5" />
            <Roles
              v-if="drawer"
              :permission="currentMember.permissions"
              :update-roles="currentMember.role"
              @updateRoles="saveRoles"
              from-member-page
              @updateTheRole="saveTheRole"
            />
            <div
              class="text-center"
              v-if="currentMember?.role?.id != 'e5n28GYL1J'"
            >
              <v-btn
                :disabled="updateLoading"
                :loading="updateLoading"
                :outlined="$vuetify.theme.dark"
                class="py-7 px-10 text-transform-none mt-15"
                color="primary"
                @click="update"
              >
                <span class="font14"> Update </span>
              </v-btn>
            </div>
          </SidePanel>
        </template>
      </div>
      <div v-else class="mt-15 no-data-found">
        <inline-svg
          class="mt-6 svg-icon empty_icon"
          src="/media/placeholders/error.svg"
        />
        <p class="mt-4 empty-title bold-text text-dark text-center">
          No members to show
        </p>
        <p
          class="mt-4 mb-5 empty-sub-title font-weight-normal text-dark text-center"
        >
          It seems that you don't have the permission to view the team members
        </p>
      </div>
    </div>

    <Modal v-model="showUserDeactiveModal" @close="showUserDeactiveModal">
      <template #card>
        <v-card>
          <v-card-title class="font-weight-bold"
            >{{ selectedMember.status == 1 ? "Deactivate" : "Activate" }}
            member
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="my-4 message">
            <p>
              Are you sure you want to
              {{ selectedMember.status == 1 ? "deactivate" : "activate" }} this
              member from this team?
            </p>

            <v-alert
              border="left"
              class="mt-3"
              color="info"
              icon="info"
              outlined
            >
              This member will lose the access to all the following:
              <div v-if="readMore">
                <br />

                <ul>
                  <li>Apps</li>
                  <li>Releases</li>
                  <li>Invites</li>
                  <li>Members</li>
                  <li>Timeline</li>
                  <li>Notifications</li>
                </ul>

                <br />
                This member can be reactivated at any time and one slot will be
                available for other members.
              </div>
              <b v-if="!readMore" class="pointer" @click="readMore = !readMore">
                {{ "Read more..." }}
              </b>
              <br />
            </v-alert>
          </v-card-text>
          <v-divider />
          <v-card-actions class="py-3">
            <v-spacer />
            <v-btn
              :disabled="loading"
              class="text-transform-none"
              color="primary "
              text
              @click="showUserDeactiveModal = false"
              >No
            </v-btn>
            <v-btn
              :loading="loading"
              :outlined="$vuetify.theme.dark"
              class="text-transform-none"
              color="primary"
              @click="enableDisableMembers"
              >Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </Modal>
    <Modal v-model="showTransferOwnerModal">
      <template #card>
        <template v-if="!transferSuccess">
          <transfer-ownership
            :selected-member="selectedTeamMember"
            from-member-list
            @close="(showTransferOwnerModal = false), (transferSuccess = false)"
            @success="afterSuccessRequest"
          ></transfer-ownership>
        </template>
        <template v-else>
          <v-card>
            <v-card-title>
              <div>Verify your action</div>
              <v-spacer></v-spacer>
              <v-btn icon @click="showTransferOwnerModal = false">
                <v-icon v-text="'mdi-close'"></v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="py-10">
              <verification-code-checker
                :memberDetail="{
                  user_id: selectedTeamMember.user_id,
                  message: transferMessage,
                }"
                action="transfer_ownership"
                from-verify-ownership
                @next="afterVerify"
              ></verification-code-checker>
            </v-card-text>
          </v-card>
        </template>
      </template>
    </Modal>
    <Modal
      v-model="showStartTrialModal"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      :width="$vuetify.breakpoint.mdAndUp ? 880 : ''"
      no-py
      @close="showStartTrialModal = false"
    >
      <template #message>
        <start-trial-modal
          :tracker-event="trackerEvent"
          from-members-page
          message="Your team has reached the maximum number of members"
          trigger-from="moreTeam"
          @close="showStartTrialModal = false"
          @open-subscribe="openSubscriptionModal = true"
        ></start-trial-modal>
      </template>
    </Modal>
    <Modal
      v-model="showHelpModal"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      width="700"
    >
      <!-- sub-title="UDIDs are essential for building and distributing iOS apps to specific devices for testing. <br /><br /> They are used in provisioning and distribution profiles for ad-hoc and TestFlight distribution. Without UDIDs, you cannot include devices in the profiles, and therefore cannot distribute the app for testing." -->
      <template #card>
        <demo-card
          v-if="showHelpModal"
          :event-type="eventName"
          :show-checkbox="showCheckbox"
          document-link="https://help.testapp.io/faq/how-to-add-udid-to-apple-developer-account/#how-to-register-udid-with-a-developer-account"
          event-name="Download UDIDs"
          i-frame-link="https://demo.arcade.software/w5ddiLruSIZFuJf1mtee?embed"
          sub-title="UDIDs are essential for distributing iOS apps to specific devices for testing."
          title="Downloading iOS UDIDs"
          @close="showHelpModal = false"
        ></demo-card>
      </template>
    </Modal>
  </div>
</template>

<script>
import Roles from "@/view/components/Team/Members/Roles.vue";
import {
  CHECK_MEMBER_LIMIT,
  DISABLE_ENABLE_MEMBERS,
  DOWNLOAD_ALL_UDID,
  DOWNLOAD_MEMBER_UDID,
  GET_MEMBERS,
  REMOVE_MEMBER,
  UPDATE_MEMBER,
} from "@/store/team/members.module.js";
import {
  GET_MEMBER_APPS_LIST,
  GET_MEMBER_DEVICE_LIST,
} from "@/store/team/team.module";
import { mapGetters, mapMutations, mapState } from "vuex";
import { currentTimestamp, get, isEmpty } from "@/core/services/helper.service";
import ProfileImage from "@/view/components/User/Image.vue";
import StorageService from "@/core/services/localstorage.service";
import DeviceInfo from "@/view/components/Team/Members/DeviceInfoPanel";
import AppsInfo from "@/view/components/Team/AppsInfo";
import ActionDropdown from "@/view/components/Common/ActionDropdown";
import StartTrialModal from "@/view/components/Common/StartTrialModal";
import TransferOwnership from "@/view/components/Team/Members/TransferOwnership";
import VerificationCodeChecker from "@/view/components/Common/VerificationCodeChecker";
import DemoCard from "@/view/components/Common/DemoCard";
import DatatableWrapper from "@/view/components/App/DatatableWrapper";

export default {
  components: {
    DemoCard,
    VerificationCodeChecker,
    TransferOwnership,
    ActionDropdown,
    AppsInfo,
    Roles,
    DeviceInfo,
    StartTrialModal,
    ProfileImage,
    DatatableWrapper,
  },
  props: {
    teamError: {
      type: [String, Boolean],
      default: null,
    },
  },
  data() {
    return {
      get,
      dialog: false,
      drawer: null,
      busy: false,
      showHelpModal: false,
      eventName: "",
      selectedTeamMember: null,
      transferSuccess: false,
      showTransferOwnerModal: false,
      transferMessage: "",
      selectedRoles: null,
      mainRole: null,
      totalMembers: 0,
      trackerEvent: {},
      devices: [],
      showAppInfo: false,
      selectedMember: {},
      showUpgradeInfo: false,
      memberDetails: {},
      readMore: false,
      showCheckbox: false,
      openHelpModal: false,
      loading: false,
      updateLoading: false,
      showStartTrialModal: false,
      drawerLoading: false,
      showDeviceInfo: false,
      currentMember: {},
      member: {},
      times: 0,
      removeLoader: false,
      showUserDeactiveModal: false,
      page: 1,
      steps: [
        {
          target: "#downloadUDID",
          shouldShowOverlay: true,
          content:
            "UDIDs are essential for distributing iOS apps to specific devices for testing.",
        },
      ],
      tourOptions: {
        overlay: {
          background: "yellow",
          opacity: 0.5,
        },
        highlight: true,
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: "Skip tour",
          buttonPrevious: "Previous",
          buttonNext: "Next",
          buttonStop: "Start",
        },
      },
      pagination: {
        page: 1,
        offset: 0,
        limit: 10,
        itemsPerPage: 10,
        totalItems: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      appInfo: "getMemberAppList",
      totalMembersData: "getTotalMembersData",
      allMembers: "getAllMembers",
      getTotalMembersCount: "getTotalMembersCount",
      getTeamPlanLimits: "getTeamPlanLimits",
      getTeamTrial: "getTeamTrial",
      getRoleID: "getRoleID",
      getInactiveUserMessage: "getInactiveUserMessage",
    }),
    IsActiveTrial() {
      return this.getTeamTrial && !this.getTeamTrial.status;
    },
    memberHeaders() {
      return [
        {
          text: "",
          align: "start",
          sortable: false,
          width: "40px",
          permissions: true,
          value: "avatar",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          permissions: true,
          width: "30%",
          value: "name",
        },
        {
          text: "Role",
          align: "start",
          sortable: false,
          permissions: true,
          width: "10%",
          value: "role",
        },
        {
          text: "Apps",
          align: "start",
          permissions: this.canManageTeam,
          sortable: false,
          width: "10%",
          value: "apps",
        },
        {
          text: "Devices",
          align: "start",
          permissions: this.canManageTeam,
          sortable: false,
          width: "10%",
          value: "devices",
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          permissions: true,
          width: "15%",
          value: "status",
        },
        {
          text: "Last activity",
          align: "start",
          sortable: false,
          permissions: this.canManageTeamMembers,
          width: "120",
          value: "last_activity",
        },
        {
          text: "Joined",
          align: "start",
          sortable: false,
          permissions: true,
          width: "120",
          value: "join",
        },
        {
          text: "",
          align: "end",
          permissions: true,
          sortable: false,
          width: "10%",
          value: "action",
        },
      ];
    },
    headers() {
      return this.memberHeaders.filter((header) => header.permissions);
    },
    ...mapState({
      memberInvitesLoader: (state) =>
        get(state, "manageTeam.memberInvitesLoader", ""),
      memberLoader: (state) => get(state, "manageTeam.memberLoader", ""),
      roles: (state) => get(state, "team.roles", ""),
    }),
    loggedUserRole() {
      return this.getRoleID === "NmeYG02DJ8" ? "Owner" : "";
    },
    loggedUser() {
      return StorageService.getItem("user")
        ? StorageService.getItem("user").id
        : "";
    },
    noData() {
      return isEmpty(this.allMembers);
    },
    lastId() {
      return this.allMembers[this.allMembers?.length - 1]?.id || "";
    },
  },
  watch: {
    "pagination.page"(val, oldVal) {
      let offset = (val - 1) * 10;
      let limit = val * 10;
      // checked if it is next or previous
      if (val > oldVal) {
        //  if next and all the data are already fetched
        if (this.totalMembersData.length > oldVal * 10) {
          let data = this.totalMembersData?.slice(offset, limit);
          this.setPreviousMembersData(data);
        } else {
          this.checkMember(this.lastId);
        }
      } else {
        // if it is previous then show already fetched data
        let data = this.totalMembersData.slice(offset, limit);
        this.setPreviousMembersData(data);
      }
    },
    getTotalMembersCount(val) {
      this.pagination.totalItems = val;
    },
  },
  created() {
    this.checkMember();
  },
  mounted() {
    if (this.$route.query.from) {
      this.$tours["memberTour"]?.start();
    }
  },
  methods: {
    ...mapMutations({
      setAppMemberList: "setAppMemberList",
      setPreviousMembersData: "setPreviousMembersData",
      setTotalMembersCount: "setTotalMembersCount",
      setAllMembers: "setAllMembers",
      removeMemberFromList: "removeMemberFromList",
      updateAllMemebers: "updateAllMemebers",
    }),
    openUDIDHelpModal(memberID) {
      this.showHelpModal = true;
      this.showCheckbox = false;
      if (memberID && this.member?.id) {
        this.eventName = `/team/members/${this.member.id}`;
      }
    },
    openDownloadHelpModal() {
      const popup = StorageService.getItem("popup");
      if (!popup || !popup.help_udid) {
        this.showHelpModal = true;
        this.showCheckbox = true;
      } else if (popup && popup.help_udid === "false") {
        this.showHelpModal = true;
        this.showCheckbox = true;
      }
    },
    downloadMembersUDID(memberID) {
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(DOWNLOAD_MEMBER_UDID, this.member.id)
        .then(async (response) => {
          if (memberID) {
            this.eventName = `/team/members/${this.member.id}`;
          }
          this.openDownloadHelpModal();
          const contentType = response?.headers?.["content-type"];
          if (
            contentType === "application/xml" ||
            contentType === "application/octet-stream"
          ) {
            const blob = new Blob([response.data], { type: "application/xml" });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "testappio_device_list.deviceids");
            link.style.display = "none";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            this.busy = false;
          }
        })
        .catch((err) => {
          this.busy = false;
          this.notifyErrorMessage(err.message);
        });
    },
    downloadAllUDID() {
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(DOWNLOAD_ALL_UDID)
        .then((response) => {
          this.openDownloadHelpModal();
          const xmlBody = response;
          const blob = new Blob([xmlBody], { type: "application/xml" });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.style.display = "none";
          link.setAttribute("download", "testappio_device_list.deviceids");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
          this.busy = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.busy = false;
        });
    },
    afterVerify() {
      this.showTransferOwnerModal = false;
      this.transferSuccess = false;
      this.checkMember();
    },
    afterSuccessRequest() {
      this.transferSuccess = true;
    },
    saveTheRole(v) {
      this.mainRole = v;
    },
    saveRoles(v) {
      this.selectedRoles = v;
    },
    openTransferOwnerModal(detail) {
      this.selectedTeamMember = detail;
      this.showTransferOwnerModal = true;
    },
    openUserDeactiveModal(detail) {
      this.selectedMember = detail;
      if (detail.status == 1) {
        this.showUserDeactiveModal = true;
      } else {
        this.$store
          .dispatch(CHECK_MEMBER_LIMIT)
          .then(() => {
            this.enableDisableMembers();
          })
          .catch((err) => {
            if (err.status === 402) {
              this.showStartTrialModal = true;
            } else {
              this.notifyErrorMessage(err.message);
            }
          });
      }
    },
    showDevice(member) {
      this.member = member;
      this.showDeviceInfo = true;
      if (this.drawerLoading) return;
      this.drawerLoading = true;
      this.$store
        .dispatch(GET_MEMBER_DEVICE_LIST, member.id)
        .then((response) => {
          this.drawerLoading = false;
          this.devices = response.results.data;
        })
        .catch((err) => {
          this.drawerLoading = false;
          this.notifyErrorMessage(err.message);
        });
    },
    enableDisableMembers() {
      if (this.loading) return;
      this.loading = true;
      const dataToPost = {
        id: this.selectedMember.id,
        action:
          this.selectedMember && this.selectedMember.status == 1
            ? "deactivate"
            : "activate",
      };
      this.$store
        .dispatch(DISABLE_ENABLE_MEMBERS, dataToPost)
        .then((response) => {
          let dataToAppend = {
            ...this.selectedMember,
          };
          this.notifyUserMessage({ message: response.message });
          const index = this.allMembers.findIndex(
            (el) => el.id === this.selectedMember.id
          );
          dataToAppend.last_activity = parseInt(currentTimestamp());
          dataToAppend.status = this.selectedMember.status == 1 ? 2 : 1;
          this.updateAllMemebers({ index: index, dataToAppend: dataToAppend });
          const count =
            dataToPost.action == "deactivate"
              ? this.getTotalMembersCount - 1
              : this.getTotalMembersCount + 1;
          this.setTotalMembersCount(count);
          this.loading = false;
          this.showUserDeactiveModal = false;
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
        });
    },
    setCurrentMemberAndRemove(member) {
      this.currentMember = member;
      this.dialog = true;
    },
    setCurrentMemberAndUpdate(member) {
      this.currentMember = member;
      this.drawer = true;
    },
    showApps(member) {
      this.currentMember = member;
      this.showAppInfo = true;
      if (this.drawerLoading) return;
      this.drawerLoading = true;
      if (member) {
        this.selectedMember = member;
      }
      this.$store
        .dispatch(
          GET_MEMBER_APPS_LIST,
          this.selectedMember ? this.selectedMember.id : member.id
        )
        .then((response) => {
          this.drawerLoading = false;
          this.setAppMemberList(response.results.data);
          this.memberDetails = {
            name: this.selectedMember ? this.selectedMember.name : member.name,
            id: this.selectedMember ? this.selectedMember.id : member.id,
          };
        })
        .catch((err) => {
          this.drawerLoading = false;
          this.notifyErrorMessage(err.message);
        });
    },
    cancelRemoveMember() {
      this.dialog = false;
    },
    removeMember() {
      this.removeLoader = true;
      this.$store
        .dispatch(REMOVE_MEMBER, this.currentMember.id)
        .then(() => {
          this.removeMemberFromList({
            id: this.currentMember.id,
            status: this.currentMember.status,
          });
          this.dialog = false;
          this.removeLoader = false;
        })
        .catch((err) => {
          this.dialog = false;
          this.removeLoader = false;
          this.notifyErrorMessage(err.message);
        });
    },
    update() {
      this.updateLoading = true;
      const data = {
        role_id: this.mainRole,
        permissions: this.selectedRoles,
      };
      this.$store
        .dispatch(UPDATE_MEMBER, { data, id: this.currentMember.id })
        .then(() => {
          this.drawer = false;
          this.updateLoading = false;
          let dataToAppend = {
            ...this.currentMember,
          };
          const index = this.allMembers.findIndex(
            (el) => el.id === this.currentMember.id
          );
          const allRoles = this.roles.find((el) => el.id === data.role_id);
          dataToAppend.role = {
            id: data.role_id,
            name: allRoles ? allRoles.name : "",
          };
          dataToAppend.last_activity = parseInt(currentTimestamp());
          dataToAppend.created_at = this.allMembers[index]?.created_at;
          dataToAppend.permissions = { ...data.permissions };
          this.updateAllMemebers({ index: index, dataToAppend: dataToAppend });
        })
        .catch((err) => {
          this.updateLoading = false;
          this.notifyErrorMessage(err.message);
        });
    },
    checkMember(lastID) {
      if (!this.teamError) {
        this.loading = true;
        this.$store
          .dispatch(GET_MEMBERS, lastID)
          .then((response) => {
            if (response && response.results && response.results.meta) {
              this.totalMembers = response.results.meta.count || 0;
              if (!lastID) {
                this.pagination.totalItems = response.results.meta.count || 0;
              }
            }
          })
          .catch((err) => {
            this.notifyErrorMessage(err.message);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    handleOnChange(value) {
      this.pagination.page = value;
    },
  },
};
</script>

<style scoped>
.v-tour__target--highlighted {
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
  pointer-events: none;
}

.overlay {
  height: 100%;
  position: absolute;
  z-index: 1;
  display: flex;
  pointer-events: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: rgba(181, 181, 195, 0.3);
}
</style>
