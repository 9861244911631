var render = function render(){
  var _vm$currentMember, _vm$currentMember$rol;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-tour', {
    staticStyle: {
      "background-color": "#ffffff",
      "color": "#0067ce"
    },
    attrs: {
      "options": _vm.tourOptions,
      "steps": _vm.steps,
      "name": "memberTour"
    }
  }), _c('div', {
    staticClass: "members"
  }, [!_vm.isInfoLoaded && _vm.canManageInvite ? _c('v-row', {
    attrs: {
      "flat": "",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "mb-4 text-right"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "id": "downloadUDID",
      "color": "primary",
      "outlined": ""
    },
    on: {
      "click": _vm.downloadAllUDID
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-download")]), _vm._v(" Download iOS UDIDs ")], 1), _c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": _vm.openUDIDHelpModal
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "20"
    }
  }, [_vm._v("mdi-help-circle-outline")])], 1)], 1)], 1)], 1)], 1) : _vm._e(), !_vm.isInfoLoaded && _vm.canViewTeamMembers ? _c('div', [_vm.loading ? _c('v-skeleton-loader', {
    staticClass: "transparent",
    attrs: {
      "type": "table-thead, table-tbody"
    }
  }) : [_c('DatatableWrapper', {
    attrs: {
      "loading": _vm.loading,
      "pagination": _vm.pagination
    },
    on: {
      "onChange": _vm.handleOnChange
    }
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.allMembers,
      "mobile-breakpoint": "0",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "item.avatar",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('ProfileImage', {
          staticClass: "my-1",
          attrs: {
            "alt": item.name,
            "image": item.image
          }
        })];
      }
    }, {
      key: "item.name",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm.loggedUser == item.user_id ? _c('b', [_vm._v(" You ")]) : _c('b', [_vm._v(_vm._s(item.name))]), _c('div', [_vm._v(" " + _vm._s(item.email) + " ")])];
      }
    }, {
      key: "item.role",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.get(item, "role.name", "")) + " ")];
      }
    }, {
      key: "item.apps",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.showApps(item);
            }
          }
        }, [_c('b', {
          staticClass: "primary--text"
        }, [_vm._v(" " + _vm._s(_vm.get(item, "stats.apps", "")) + " ")])])];
      }
    }, {
      key: "item.devices",
      fn: function fn(_ref5) {
        var _item$stats3, _item$stats3$devices, _item$stats4, _item$stats4$devices;
        var item = _ref5.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref6) {
              var _item$stats, _item$stats$devices, _item$stats2, _item$stats2$devices;
              var on = _ref6.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    return _vm.showDevice(item);
                  }
                }
              }, on), [_c('b', _vm._g({
                staticClass: "primary--text"
              }, on), [_vm._v(" " + _vm._s(((item === null || item === void 0 ? void 0 : (_item$stats = item.stats) === null || _item$stats === void 0 ? void 0 : (_item$stats$devices = _item$stats.devices) === null || _item$stats$devices === void 0 ? void 0 : _item$stats$devices.ios) || 0) + ((item === null || item === void 0 ? void 0 : (_item$stats2 = item.stats) === null || _item$stats2 === void 0 ? void 0 : (_item$stats2$devices = _item$stats2.devices) === null || _item$stats2$devices === void 0 ? void 0 : _item$stats2$devices.android) || 0)) + " ")])])];
            }
          }], null, true)
        }, [_c('ul', [_c('li', [_vm._v("Android: " + _vm._s((item === null || item === void 0 ? void 0 : (_item$stats3 = item.stats) === null || _item$stats3 === void 0 ? void 0 : (_item$stats3$devices = _item$stats3.devices) === null || _item$stats3$devices === void 0 ? void 0 : _item$stats3$devices.android) || 0))]), _c('li', [_vm._v("iOS: " + _vm._s((item === null || item === void 0 ? void 0 : (_item$stats4 = item.stats) === null || _item$stats4 === void 0 ? void 0 : (_item$stats4$devices = _item$stats4.devices) === null || _item$stats4$devices === void 0 ? void 0 : _item$stats4$devices.ios) || 0))])])])];
      }
    }, {
      key: "item.status",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('span', {
          class: item.status == 1 ? 'success--text' : 'error--text'
        }, [_vm._v(" " + _vm._s(item.status == 1 ? "Active" : "Inactive") + " ")])];
      }
    }, {
      key: "item.last_activity",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [item.last_activity ? _c('div', [_c('vue-hoverable-date', {
          attrs: {
            "date": item.last_activity
          }
        })], 1) : _c('div', [_vm._v("N/A")])];
      }
    }, {
      key: "item.join",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('vue-hoverable-date', {
          attrs: {
            "date": item.created_at
          }
        })];
      }
    }, {
      key: "item.action",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [!_vm.getInactiveUserMessage && !_vm.getIsUserDisabled ? _c('action-dropdown', {
          scopedSlots: _vm._u([{
            key: "list",
            fn: function fn() {
              return [_vm.canManageTeam && item.role.name !== 'Owner' && _vm.loggedUser !== item.user_id && _vm.loggedUserRole === 'Owner' && _vm.canManageTeam && item.status == 1 ? _c('v-list-item', {
                on: {
                  "click": function click($event) {
                    return _vm.openTransferOwnerModal(item);
                  }
                }
              }, [_c('v-icon', {
                attrs: {
                  "small": ""
                },
                domProps: {
                  "textContent": _vm._s('mdi-account-switch')
                }
              }), _vm._v(" Transfer ownership ")], 1) : _vm._e(), _vm.loggedUser !== item.user_id && item.status == 2 && _vm.canManageTeam ? _c('v-list-item', {
                on: {
                  "click": function click($event) {
                    return _vm.openUserDeactiveModal(item);
                  }
                }
              }, [_c('v-icon', {
                attrs: {
                  "small": ""
                },
                domProps: {
                  "textContent": _vm._s(item.status == 1 ? 'mdi-account-remove' : 'mdi-account-check')
                }
              }), _vm._v(" " + _vm._s(item.status == 1 ? "Deactivate" : "Activate") + " ")], 1) : _vm._e(), _vm.loggedUser !== item.user_id && item.status == 1 && _vm.canManageTeam ? _c('v-list-item', {
                on: {
                  "click": function click($event) {
                    return _vm.setCurrentMemberAndUpdate(item);
                  }
                }
              }, [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("edit")]), _vm._v(" edit ")], 1) : _vm._e(), _vm.canManageTeamMembers ? _c('v-list-item', {
                on: {
                  "click": function click($event) {
                    return _vm.showDevice(item);
                  }
                }
              }, [_c('v-icon', {
                attrs: {
                  "left": "",
                  "small": ""
                }
              }, [_vm._v("devices")]), _vm._v(" Devices ")], 1) : _vm._e(), _vm.loggedUser === item.user_id || _vm.canManageTeam ? _c('v-list-item', {
                attrs: {
                  "id": "showAppBtnMember"
                },
                on: {
                  "click": function click($event) {
                    return _vm.showApps(item);
                  }
                }
              }, [_c('v-icon', {
                attrs: {
                  "left": "",
                  "small": ""
                }
              }, [_vm._v("apps")]), _vm._v(" Apps ")], 1) : _vm._e(), _vm.loggedUser !== item.user_id && _vm.canManageTeam ? _c('v-list-item', {
                on: {
                  "click": function click($event) {
                    return _vm.setCurrentMemberAndRemove(item);
                  }
                }
              }, [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("cancel")]), _vm._v(" remove ")], 1) : _vm._e()];
            },
            proxy: true
          }], null, true)
        }) : _vm._e()];
      }
    }, {
      key: "footer.page-text",
      fn: function fn() {
        return [_c('div', [_c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "color": "darkGrey",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.checkMember();
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "color": _vm.$vuetify.theme.dark ? '' : 'grey',
            "left": ""
          },
          domProps: {
            "textContent": _vm._s('mdi-reload')
          }
        }), _vm._v(" Reload ")], 1), _vm._v(" Total: "), _c('b', [_vm._v(_vm._s(_vm.getTotalMembersCount))])], 1)];
      },
      proxy: true
    }], null, false, 3721520257)
  })], 1), _c('SidePanel', {
    attrs: {
      "width": "600"
    },
    model: {
      value: _vm.showDeviceInfo,
      callback: function callback($$v) {
        _vm.showDeviceInfo = $$v;
      },
      expression: "showDeviceInfo"
    }
  }, [_c('DeviceInfo', {
    attrs: {
      "busy": _vm.busy,
      "devices": _vm.devices,
      "loading": _vm.drawerLoading,
      "member": _vm.member
    },
    on: {
      "download-udid": _vm.downloadMembersUDID,
      "download-udid-info": _vm.openUDIDHelpModal
    }
  })], 1), _c('SidePanel', {
    model: {
      value: _vm.showAppInfo,
      callback: function callback($$v) {
        _vm.showAppInfo = $$v;
      },
      expression: "showAppInfo"
    }
  }, [_vm.showAppInfo ? _c('AppsInfo', {
    attrs: {
      "current-member": _vm.currentMember.user_id,
      "loading": _vm.drawerLoading,
      "logged-user": _vm.loggedUser,
      "member": _vm.memberDetails,
      "modals-apps": _vm.appInfo,
      "selected-member": _vm.selectedMember
    },
    on: {
      "close": function close($event) {
        _vm.showAppInfo = false;
      },
      "refresh": _vm.showApps
    }
  }) : _vm._e()], 1), _c('Modal', {
    on: {
      "close": function close($event) {
        _vm.dialog = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_vm.dialog ? _c('v-card', [_c('v-card-title', {
          staticClass: "bold-text"
        }, [_vm._v("Remove member")]), _c('v-divider'), _c('v-card-text', {
          staticClass: "my-4 message"
        }, [_c('p', [_vm._v(" Are you sure you want to remove "), _c('b', [_vm._v(_vm._s(_vm.currentMember.name))]), _vm._v(" from this team? ")])]), _c('v-divider'), _c('v-card-actions', {
          staticClass: "py-3"
        }, [_c('div', [_vm._v("No undo for this action.")]), _c('v-spacer'), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "color": "primary",
            "text": ""
          },
          on: {
            "click": _vm.cancelRemoveMember
          }
        }, [_vm._v("No ")]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "loading": _vm.removeLoader,
            "outlined": _vm.$vuetify.theme.dark,
            "color": "primary"
          },
          on: {
            "click": _vm.removeMember
          }
        }, [_vm._v(" Yes ")])], 1)], 1) : _vm._e()];
      },
      proxy: true
    }], null, false, 3913691371),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }), _c('SidePanel', {
    attrs: {
      "loading": _vm.updateLoading
    },
    on: {
      "close": function close($event) {
        _vm.drawer = false;
      }
    },
    model: {
      value: _vm.drawer,
      callback: function callback($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [_c('h1', {
    staticClass: "text-center text-capitalize display-1 mb-15"
  }, [_vm._v("edit")]), _vm.currentMember ? _c('h3', {
    staticClass: "col-12 text-center"
  }, [_vm._v(" " + _vm._s(_vm.currentMember.name) + " ")]) : _vm._e(), _c('v-divider', {
    staticClass: "mb-5"
  }), _vm.drawer ? _c('Roles', {
    attrs: {
      "permission": _vm.currentMember.permissions,
      "update-roles": _vm.currentMember.role,
      "from-member-page": ""
    },
    on: {
      "updateRoles": _vm.saveRoles,
      "updateTheRole": _vm.saveTheRole
    }
  }) : _vm._e(), ((_vm$currentMember = _vm.currentMember) === null || _vm$currentMember === void 0 ? void 0 : (_vm$currentMember$rol = _vm$currentMember.role) === null || _vm$currentMember$rol === void 0 ? void 0 : _vm$currentMember$rol.id) != 'e5n28GYL1J' ? _c('div', {
    staticClass: "text-center"
  }, [_c('v-btn', {
    staticClass: "py-7 px-10 text-transform-none mt-15",
    attrs: {
      "disabled": _vm.updateLoading,
      "loading": _vm.updateLoading,
      "outlined": _vm.$vuetify.theme.dark,
      "color": "primary"
    },
    on: {
      "click": _vm.update
    }
  }, [_c('span', {
    staticClass: "font14"
  }, [_vm._v(" Update ")])])], 1) : _vm._e()], 1)]], 2) : _c('div', {
    staticClass: "mt-15 no-data-found"
  }, [_c('inline-svg', {
    staticClass: "mt-6 svg-icon empty_icon",
    attrs: {
      "src": "/media/placeholders/error.svg"
    }
  }), _c('p', {
    staticClass: "mt-4 empty-title bold-text text-dark text-center"
  }, [_vm._v(" No members to show ")]), _c('p', {
    staticClass: "mt-4 mb-5 empty-sub-title font-weight-normal text-dark text-center"
  }, [_vm._v(" It seems that you don't have the permission to view the team members ")])], 1)], 1), _c('Modal', {
    on: {
      "close": _vm.showUserDeactiveModal
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('v-card', [_c('v-card-title', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(_vm.selectedMember.status == 1 ? "Deactivate" : "Activate") + " member ")]), _c('v-divider'), _c('v-card-text', {
          staticClass: "my-4 message"
        }, [_c('p', [_vm._v(" Are you sure you want to " + _vm._s(_vm.selectedMember.status == 1 ? "deactivate" : "activate") + " this member from this team? ")]), _c('v-alert', {
          staticClass: "mt-3",
          attrs: {
            "border": "left",
            "color": "info",
            "icon": "info",
            "outlined": ""
          }
        }, [_vm._v(" This member will lose the access to all the following: "), _vm.readMore ? _c('div', [_c('br'), _c('ul', [_c('li', [_vm._v("Apps")]), _c('li', [_vm._v("Releases")]), _c('li', [_vm._v("Invites")]), _c('li', [_vm._v("Members")]), _c('li', [_vm._v("Timeline")]), _c('li', [_vm._v("Notifications")])]), _c('br'), _vm._v(" This member can be reactivated at any time and one slot will be available for other members. ")]) : _vm._e(), !_vm.readMore ? _c('b', {
          staticClass: "pointer",
          on: {
            "click": function click($event) {
              _vm.readMore = !_vm.readMore;
            }
          }
        }, [_vm._v(" " + _vm._s("Read more...") + " ")]) : _vm._e(), _c('br')])], 1), _c('v-divider'), _c('v-card-actions', {
          staticClass: "py-3"
        }, [_c('v-spacer'), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "disabled": _vm.loading,
            "color": "primary ",
            "text": ""
          },
          on: {
            "click": function click($event) {
              _vm.showUserDeactiveModal = false;
            }
          }
        }, [_vm._v("No ")]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "loading": _vm.loading,
            "outlined": _vm.$vuetify.theme.dark,
            "color": "primary"
          },
          on: {
            "click": _vm.enableDisableMembers
          }
        }, [_vm._v("Yes ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showUserDeactiveModal,
      callback: function callback($$v) {
        _vm.showUserDeactiveModal = $$v;
      },
      expression: "showUserDeactiveModal"
    }
  }), _c('Modal', {
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [!_vm.transferSuccess ? [_c('transfer-ownership', {
          attrs: {
            "selected-member": _vm.selectedTeamMember,
            "from-member-list": ""
          },
          on: {
            "close": function close($event) {
              _vm.showTransferOwnerModal = false, _vm.transferSuccess = false;
            },
            "success": _vm.afterSuccessRequest
          }
        })] : [_c('v-card', [_c('v-card-title', [_c('div', [_vm._v("Verify your action")]), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              _vm.showTransferOwnerModal = false;
            }
          }
        }, [_c('v-icon', {
          domProps: {
            "textContent": _vm._s('mdi-close')
          }
        })], 1)], 1), _c('v-card-text', {
          staticClass: "py-10"
        }, [_c('verification-code-checker', {
          attrs: {
            "memberDetail": {
              user_id: _vm.selectedTeamMember.user_id,
              message: _vm.transferMessage
            },
            "action": "transfer_ownership",
            "from-verify-ownership": ""
          },
          on: {
            "next": _vm.afterVerify
          }
        })], 1)], 1)]];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showTransferOwnerModal,
      callback: function callback($$v) {
        _vm.showTransferOwnerModal = $$v;
      },
      expression: "showTransferOwnerModal"
    }
  }), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.mdAndDown,
      "width": _vm.$vuetify.breakpoint.mdAndUp ? 880 : '',
      "no-py": ""
    },
    on: {
      "close": function close($event) {
        _vm.showStartTrialModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('start-trial-modal', {
          attrs: {
            "tracker-event": _vm.trackerEvent,
            "from-members-page": "",
            "message": "Your team has reached the maximum number of members",
            "trigger-from": "moreTeam"
          },
          on: {
            "close": function close($event) {
              _vm.showStartTrialModal = false;
            },
            "open-subscribe": function openSubscribe($event) {
              _vm.openSubscriptionModal = true;
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showStartTrialModal,
      callback: function callback($$v) {
        _vm.showStartTrialModal = $$v;
      },
      expression: "showStartTrialModal"
    }
  }), _c('Modal', {
    attrs: {
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "width": "700"
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_vm.showHelpModal ? _c('demo-card', {
          attrs: {
            "event-type": _vm.eventName,
            "show-checkbox": _vm.showCheckbox,
            "document-link": "https://help.testapp.io/faq/how-to-add-udid-to-apple-developer-account/#how-to-register-udid-with-a-developer-account",
            "event-name": "Download UDIDs",
            "i-frame-link": "https://demo.arcade.software/w5ddiLruSIZFuJf1mtee?embed",
            "sub-title": "UDIDs are essential for distributing iOS apps to specific devices for testing.",
            "title": "Downloading iOS UDIDs"
          },
          on: {
            "close": function close($event) {
              _vm.showHelpModal = false;
            }
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showHelpModal,
      callback: function callback($$v) {
        _vm.showHelpModal = $$v;
      },
      expression: "showHelpModal"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }