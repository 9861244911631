var render = function render(){
  var _vm$member, _vm$member2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', [_vm._v(" Apps "), _c('v-spacer'), _vm.loggedUser !== _vm.currentMember ? _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "depressed": "",
      "id": "addAppMemberBtn",
      "color": "primary",
      "disabled": _vm.selectedMember.status == 2,
      "outlined": _vm.$vuetify.theme.dark
    },
    on: {
      "click": _vm.showMemberAddFormModal
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" Add App ")], 1) : _vm._e(), _vm.fromIntegration ? _c('v-btn', {
    staticClass: "text-transform-none mr-2",
    attrs: {
      "depressed": "",
      "id": "addAppMemberBtn",
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark
    },
    on: {
      "click": _vm.showMemberAddFormModal
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" Add app ")], 1) : _vm._e()], 1), _vm.loading ? [_c('div', {
    staticClass: "mx-4 mt-4"
  }, [_c('v-skeleton-loader', {
    staticClass: "mb-3",
    attrs: {
      "type": "heading"
    }
  }), _vm._l(8, function (i) {
    return _c('v-card', {
      key: i,
      staticClass: "mb-2 px-3",
      attrs: {
        "outlined": ""
      }
    }, [_c('v-skeleton-loader', {
      staticClass: "transparent",
      attrs: {
        "type": "list-item-avatar-two-line"
      }
    })], 1);
  })], 2)] : [_vm.showMemberAddForm ? _c('div', {
    attrs: {
      "id": "addAppForm"
    }
  }, [_c('v-card-text', {
    staticClass: "message"
  }, [_c('app-auto-complete', {
    attrs: {
      "id": "appSelectAutocomplete",
      "integration-sub-id": _vm.integrationSubId,
      "exclude-list": _vm.modalsApps,
      "add-app": "",
      "member-id": (_vm$member = _vm.member) === null || _vm$member === void 0 ? void 0 : _vm$member.id
    },
    model: {
      value: _vm.selectedApps,
      callback: function callback($$v) {
        _vm.selectedApps = $$v;
      },
      expression: "selectedApps"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "mx-2 text-transform-none",
    attrs: {
      "disabled": !_vm.selectedApps,
      "depressed": "",
      "loading": _vm.busy,
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark,
      "id": "createAppBtn"
    },
    on: {
      "click": _vm.addAppConfirm
    }
  }, [_vm._v(" Add ")])], 1)], 1) : _vm._e(), _c('v-card-text', [(_vm$member2 = _vm.member) !== null && _vm$member2 !== void 0 && _vm$member2.name ? _c('h3', {
    staticClass: "col-12 text-center"
  }, [_vm._v(" " + _vm._s(_vm.member.name) + " (" + _vm._s(_vm.searchedModalsApp.length) + ") ")]) : _vm._e(), _vm.fromIntegration ? _c('h3', {
    staticClass: "col-12 text-center"
  }, [_vm._v(" " + _vm._s(_vm.selectedSubSource.name) + " (" + _vm._s(_vm.searchedModalsApp.length) + ") ")]) : _vm._e(), _c('v-divider', {
    staticClass: "mb-5"
  }), _vm.loadingIntegration ? _c('div', [_c('v-row', _vm._l(4, function (i) {
    return _c('v-col', {
      key: i,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-skeleton-loader', {
      attrs: {
        "type": "card,list"
      }
    })], 1);
  }), 1)], 1) : _vm.searchedModalsApp.length ? _c('div', [!_vm.searchedModalsApp.length ? _c('div', {
    staticClass: "text-center bold-text title"
  }, [_vm._v(" " + _vm._s(!_vm.search ? "No apps added in this invite" : "No app match " + _vm.search + " keys") + " ")]) : _c('div', _vm._l(_vm.searchedModalsApp, function (app, index) {
    return _c('v-card', {
      key: index,
      staticClass: "mb-2 py-2 px-2",
      attrs: {
        "outlined": ""
      }
    }, [_c('v-row', {
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "7"
      }
    }, [_c('div', {
      staticClass: "d-flex align-center"
    }, [_c('div', {
      staticClass: "flex-shrink-0 mr-4 ml-1",
      staticStyle: {
        "height": "50px"
      }
    }, [_c('img', {
      attrs: {
        "src": _vm.getAppImage(app),
        "width": "50"
      },
      on: {
        "error": _vm.replaceDefaultAppImage
      }
    })]), _c('div', [_vm._v(_vm._s(app.name))])])]), _c('v-col', {
      staticClass: "pr-0",
      class: {
        'text-center': _vm.$vuetify.breakpoint.mdAndDown
      },
      attrs: {
        "cols": "3"
      }
    }, [app.platforms.includes('ios') ? _c('PlatformIcon', {
      staticClass: "mr-3",
      attrs: {
        "android": "",
        "color": app.platforms.includes('android') ? 'primary' : 'grey'
      }
    }) : _vm._e(), app.platforms.includes('ios') ? _c('PlatformIcon', {
      attrs: {
        "apple": "",
        "color": app.platforms.includes('ios') ? 'primary' : 'grey'
      }
    }) : _vm._e()], 1), _c('v-col', {
      staticClass: "px-0",
      attrs: {
        "cols": "2"
      }
    }, [_vm.loggedUser !== _vm.currentMember || _vm.fromIntegration ? _c('ActionDropdown', {
      scopedSlots: _vm._u([{
        key: "list",
        fn: function fn() {
          return [_c('v-list-item', {
            attrs: {
              "id": "removeMemberBtn"
            },
            on: {
              "click": function click($event) {
                _vm.selectedApp = app.id, _vm.removeDialog = true;
              }
            }
          }, [_c('v-icon', {
            attrs: {
              "small": ""
            }
          }, [_vm._v("cancel")]), _vm._v(" remove ")], 1)];
        },
        proxy: true
      }], null, true)
    }) : _vm._e()], 1)], 1)], 1);
  }), 1)]) : _c('div', {
    staticClass: "no-data-found"
  }, [_c('inline-svg', {
    staticClass: "mt-6 svg-icon empty_icon",
    attrs: {
      "src": "/media/placeholders/no-apps".concat(_vm.$vuetify.theme.dark ? '-dark' : '', ".svg")
    }
  }), _c('p', {
    staticClass: "mt-4 empty-title bold-text darkGrey--text text-center"
  }, [_vm._v(" No apps ")]), _c('p', {
    staticClass: "mt-4 mb-5 empty-sub-title font-weight-normal darkGrey--text text-center"
  }, [!_vm.fromIntegration ? _c('span', [_vm._v(" Add apps to members to grant them access to install or manage it ")]) : _c('span', [_vm._v(" Add apps to this integration ")])])], 1)], 1)], _c('Modal', {
    attrs: {
      "title": "",
      "message": "",
      "loading": _vm.loadingConfirm
    },
    on: {
      "onCancel": _vm.cancelRemoveDialog
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('v-card', [_c('v-card-title', [_vm._v(" Remove App ")]), _c('v-divider'), _c('v-card-text', [!_vm.fromIntegration ? _c('div', {
          staticClass: "darkGrey--text my-5 bodyFont"
        }, [_vm._v(" Are you sure you want to remove this app from this member? There's no undo for this action. ")]) : _vm._e(), _vm.fromIntegration ? _c('div', {
          staticClass: "darkGrey--text my-5 bodyFont"
        }, [_vm._v(" Are you sure you want to disconnect this app from this integration? "), _c('div', {
          staticClass: "my-5"
        }, [_c('i', [_vm._v("You can always connect it again from the integrations page inside team and app.")])])]) : _vm._e()]), _c('v-divider'), _c('v-card-actions', {
          staticClass: "py-3"
        }, [_c('v-spacer'), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "disabled": _vm.loadingConfirm,
            "text": "",
            "color": "primary"
          },
          on: {
            "click": _vm.cancelRemoveDialog
          }
        }, [_vm._v(" No ")]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "outlined": _vm.$vuetify.theme.dark,
            "disabled": _vm.loadingConfirm,
            "depressed": "",
            "color": "primary"
          },
          on: {
            "click": _vm.confirmRemove
          }
        }, [_vm._v(" " + _vm._s(_vm.fromIntegration ? "Disconnect" : "Yes") + " ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.removeDialog,
      callback: function callback($$v) {
        _vm.removeDialog = $$v;
      },
      expression: "removeDialog"
    }
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }