<template>
  <v-card>
    <v-card-title>
      <div>Transfer ownership</div>
      <v-spacer></v-spacer>
      <v-btn @click="$emit('close')" icon>
        <v-icon v-text="'mdi-close'"></v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col v-if="fromMemberList" cols="12">
          <div>
            Name: <b>{{ selectedMember.name }}</b>
          </div>
          <div>
            Current role: <b>{{ selectedMember?.role?.name }}</b>
          </div>
        </v-col>
        <v-col v-else cols="12">
          <member-select
            v-model="selectedMemberFromSelect"
            :app="null"
            single
            from-transfer-ownership
          ></member-select>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="transferMessage"
            counter="600"
            filled
            auto-grow
            v-validate="'max:600'"
            v-bind="veeValidate('message', 'Message for Owner')"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="loading"
        class="text-transform-none"
        @click="$emit('close')"
        text
      >
        Cancel
      </v-btn>
      <v-btn
        :outlined="$vuetify.theme.dark"
        :loading="loading"
        class="text-transform-none"
        @click="confirmTransferOwnership"
        color="primary"
      >
        Confirm
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import veeValidate from "@/mixins/veeValidate";
import { TRANSFER_OWNERSHIP } from "@/store/team/members.module";
import MemberSelect from "@/view/components/Common/MemberSelectSection";
export default {
  mixins: [veeValidate],
  props: {
    selectedMember: {
      type: Object,
      default() {
        return null;
      },
    },
    fromMemberList: {
      type: Boolean,
      default: false,
    },
  },
  components: { MemberSelect },
  data() {
    return {
      transferMessage: "",
      loading: false,
      selectedMemberFromSelect: {},
    };
  },
  methods: {
    confirmTransferOwnership() {
      if (this.loading) return;
      this.loading = true;
      let dataToPost = {
        user_id:
          this.selectedMemberFromSelect?.user_id ||
          this.selectedMember?.user_id,
        message: this.transferMessage,
      };
      this.$store
        .dispatch(TRANSFER_OWNERSHIP, dataToPost)
        .then((response) => {
          this.notifyUserMessage(response.message);
          this.$emit("success", dataToPost);
          this.loading = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.loading = false;
        });
    },
  },
};
</script>
