<template>
  <v-card flat>
    <v-card-title>
      Apps
      <v-spacer />
      <v-btn
        depressed
        id="addAppMemberBtn"
        color="primary"
        class="text-transform-none"
        :disabled="selectedMember.status == 2"
        :outlined="$vuetify.theme.dark"
        @click="showMemberAddFormModal"
        v-if="loggedUser !== currentMember"
      >
        <v-icon left>mdi-plus</v-icon> Add App
      </v-btn>
      <v-btn
        depressed
        id="addAppMemberBtn"
        color="primary"
        class="text-transform-none mr-2"
        :outlined="$vuetify.theme.dark"
        @click="showMemberAddFormModal"
        v-if="fromIntegration"
      >
        <v-icon left>mdi-plus</v-icon> Add app
      </v-btn>
      <!-- <v-btn v-if="fromIntegration" icon @click="$emit('close')"
        ><v-icon>mdi-close</v-icon></v-btn
      > -->
    </v-card-title>
    <!--  -->
    <template v-if="loading">
      <div class="mx-4 mt-4">
        <v-skeleton-loader type="heading" class="mb-3" />
        <v-card v-for="i in 8" :key="i" outlined class="mb-2 px-3">
          <v-skeleton-loader
            class="transparent"
            type="list-item-avatar-two-line"
          />
        </v-card>
      </div>
    </template>
    <template v-else>
      <div id="addAppForm" v-if="showMemberAddForm">
        <v-card-text class="message">
          <app-auto-complete
            id="appSelectAutocomplete"
            v-model="selectedApps"
            :integration-sub-id="integrationSubId"
            :exclude-list="modalsApps"
            add-app
            :member-id="member?.id"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="!selectedApps"
            depressed
            :loading="busy"
            @click="addAppConfirm"
            color="primary"
            :outlined="$vuetify.theme.dark"
            class="mx-2 text-transform-none"
            id="createAppBtn"
          >
            Add
          </v-btn>
        </v-card-actions>
      </div>
      <v-card-text>
        <h3 v-if="member?.name" class="col-12 text-center">
          {{ member.name }}
          ({{ searchedModalsApp.length }})
        </h3>
        <h3 v-if="fromIntegration" class="col-12 text-center">
          {{ selectedSubSource.name }}
          ({{ searchedModalsApp.length }})
        </h3>
        <v-divider class="mb-5" />
        <div v-if="loadingIntegration">
          <v-row>
            <v-col v-for="i in 4" :key="i" cols="12">
              <v-skeleton-loader type="card,list"></v-skeleton-loader>
            </v-col>
          </v-row>
        </div>
        <div v-else-if="searchedModalsApp.length">
          <div
            class="text-center bold-text title"
            v-if="!searchedModalsApp.length"
          >
            {{
              !search
                ? "No apps added in this invite"
                : "No app match " + search + " keys"
            }}
          </div>
          <div v-else>
            <v-card
              class="mb-2 py-2 px-2"
              v-for="(app, index) in searchedModalsApp"
              :key="index"
              outlined
            >
              <v-row align="center">
                <v-col cols="7">
                  <div class="d-flex align-center">
                    <div class="flex-shrink-0 mr-4 ml-1" style="height: 50px">
                      <img
                        :src="getAppImage(app)"
                        width="50"
                        @error="replaceDefaultAppImage"
                      />
                    </div>
                    <div>{{ app.name }}</div>
                  </div>
                </v-col>
                <v-col
                  cols="3"
                  class="pr-0"
                  :class="{ 'text-center': $vuetify.breakpoint.mdAndDown }"
                >
                  <PlatformIcon
                    v-if="app.platforms.includes('ios')"
                    class="mr-3"
                    android
                    :color="
                      app.platforms.includes('android') ? 'primary' : 'grey'
                    "
                  />
                  <PlatformIcon
                    v-if="app.platforms.includes('ios')"
                    apple
                    :color="app.platforms.includes('ios') ? 'primary' : 'grey'"
                  />
                </v-col>
                <v-col class="px-0" cols="2">
                  <ActionDropdown
                    v-if="loggedUser !== currentMember || fromIntegration"
                  >
                    <template #list>
                      <v-list-item
                        id="removeMemberBtn"
                        @click="(selectedApp = app.id), (removeDialog = true)"
                      >
                        <v-icon small>cancel</v-icon>
                        remove
                      </v-list-item>
                    </template>
                  </ActionDropdown>
                  <!-- <v-btn
                    id="remove-app-from-list"
                    icon
                    @click="(selectedApp = app.id), (removeDialog = true)"
                  >
                    <v-icon color="grey">cancel</v-icon>
                  </v-btn> -->
                </v-col>
              </v-row>
            </v-card>
          </div>
        </div>
        <div v-else class="no-data-found">
          <inline-svg
            class="mt-6 svg-icon empty_icon"
            :src="`/media/placeholders/no-apps${
              $vuetify.theme.dark ? '-dark' : ''
            }.svg`"
          />
          <p class="mt-4 empty-title bold-text darkGrey--text text-center">
            No apps
          </p>
          <p
            class="mt-4 mb-5 empty-sub-title font-weight-normal darkGrey--text text-center"
          >
            <span v-if="!fromIntegration">
              Add apps to members to grant them access to install or manage it
            </span>
            <span v-else> Add apps to this integration </span>
          </p>
        </div>
      </v-card-text>
    </template>
    <Modal
      v-model="removeDialog"
      title=""
      message=""
      @onCancel="cancelRemoveDialog"
      :loading="loadingConfirm"
    >
      <template #card>
        <v-card>
          <v-card-title> Remove App </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div v-if="!fromIntegration" class="darkGrey--text my-5 bodyFont">
              Are you sure you want to remove this app from this member? There's
              no undo for this action.
            </div>
            <div v-if="fromIntegration" class="darkGrey--text my-5 bodyFont">
              Are you sure you want to disconnect this app from this
              integration?
              <div class="my-5">
                <i
                  >You can always connect it again from the integrations page
                  inside team and app.</i
                >
              </div>
            </div>
          </v-card-text>
          <v-divider> </v-divider>
          <v-card-actions class="py-3">
            <v-spacer></v-spacer>
            <v-btn
              :disabled="loadingConfirm"
              text
              color="primary"
              class="text-transform-none"
              @click="cancelRemoveDialog"
            >
              No
            </v-btn>
            <v-btn
              :outlined="$vuetify.theme.dark"
              :disabled="loadingConfirm"
              depressed
              color="primary"
              class="text-transform-none"
              @click="confirmRemove"
            >
              {{ fromIntegration ? "Disconnect" : "Yes" }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </Modal>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";
import AppAutoComplete from "@/view/components/Common/AppSearch";
import { ADD_INVITE } from "@/store/apps/app.module";
import { DELETE_MEMBER } from "@/store/apps/appmember.module.js";
import ApiService from "@/core/services/api.service.js";
export default {
  components: { AppAutoComplete },
  props: {
    modalsApps: {
      type: Array,
      default() {
        return [];
      },
    },
    member: {
      type: Object,
      default() {
        return {};
      },
    },
    selectedMember: {
      type: Object,
      default() {
        return {};
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fromIntegration: {
      type: Boolean,
      default: false,
    },
    selectedSubSource: {
      type: Object,
      default() {
        return null;
      },
    },
    integrationSubId: {
      type: String,
      default: "",
    },
    integrationsEndpoint: {
      type: String,
      default: "",
    },
    // this both are IDs
    loggedUser: String,
    currentMember: String,
    // // //
  },
  data() {
    return {
      search: "",
      selectedApps: null,
      loadingConfirm: false,
      selectedApp: "",
      busy: false,
      connectedSourceApps: [],
      loadingIntegration: false,
      removeDialog: false,
      notify: {
        show: false,
        message: "Something went wrong",
        color: "",
      },
      showMemberAddForm: false,
    };
  },
  computed: {
    searchedModalsApp() {
      if (this.fromIntegration) {
        return this.connectedSourceApps;
      } else {
        return this.modalsApps.filter((app) =>
          app.name.toLowerCase().includes(this.search.toLowerCase() || "")
        );
      }
    },
  },
  watch: {
    member() {},
  },
  created() {
    if (this.fromIntegration) {
      this.getSubSourceAppList();
    }
    this.setSearchAppsInitial([]);
  },
  methods: {
    ...mapMutations({
      removeMemberAppStats: "removeMemberAppStats",
      setSearchAppsInitial: "setSearchAppsInitial",
      updateMemberAppStats: "updateMemberAppStats",
    }),
    cancelRemoveDialog() {
      this.removeDialog = false;
    },
    confirmRemove() {
      if (this.fromIntegration) {
        this.removeAppFromIntegration();
      } else {
        this.removeApps();
      }
    },
    showMemberAddFormModal() {
      this.showMemberAddForm = !this.showMemberAddForm;
    },
    addAppConfirm() {
      if (this.fromIntegration) {
        this.addAppsToIntegration();
      } else {
        this.addMember();
      }
    },
    addAppsToIntegration() {
      if (this.busy) return;
      this.busy = true;
      this.$portalApi
        .post(this.integrationsEndpoint, {
          app_id: this.selectedApps.id,
        })
        .then((response) => {
          this.showMemberAddForm = false;
          this.notifyUserMessage({ message: response.data.message });
          this.getSubSourceAppList();
          this.busy = false;
          this.$emit("success", this.integrationSubId);
        })
        .catch((err) => {
          this.notifyErrorMessage(
            err.message || "Something went wrong, please try again"
          );
          this.busy = false;
        });
    },
    getSubSourceAppList() {
      if (this.loadingIntegration) return;
      this.loadingIntegration = true;
      this.$portalApi
        .get(this.integrationsEndpoint + "s")
        .then((response) => {
          this.connectedSourceApps = response.data;

          this.loadingIntegration = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(
            err.message || "Something went wrong, please try again"
          );
          this.loadingIntegration = false;
        });
    },
    addMember() {
      if (this.busy) return;
      this.busy = true;
      const dataToPost = {
        app_id: this.selectedApps.id,
        name: this.selectedApps.name,
        platforms: this.selectedApps.platforms || [],
        details: {
          add_all_team_members: false,
          ids: [this.member?.id],
        },
      };
      this.$store
        .dispatch(ADD_INVITE, dataToPost)
        .then((response) => {
          this.notifyUserMessage({
            message: response.message || "Successfully added the member",
          });
          this.updateMemberAppStats({ count: 1, memberID: this.member.id });
          this.selectedApps = null;
          this.setSearchAppsInitial([]);
          this.showMemberAddForm = false;
          this.busy = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(
            err.message || "Something went wrong, please try again"
          );
          this.busy = false;
        });
    },
    removeAppFromIntegration() {
      if (this.loadingConfirm) return;
      this.loadingConfirm = true;
      ApiService.delete(`portal${this.integrationsEndpoint}`, {
        app_id: this.selectedApp,
      })
        .then(({ data }) => {
          this.loadingConfirm = false;
          this.removeDialog = false;
          this.getSubSourceAppList();
          this.$emit("delete", this.integrationSubId);
          this.notifyUserMessage({
            message: data.message,
          });
          this.loadingConfirm = false;
        })
        .catch((err) => {
          this.loadingConfirm = false;
          this.notifyErrorMessage(err.message);
        });
    },
    removeApps() {
      if (this.loadingConfirm) return;
      this.loadingConfirm = true;
      this.$store
        .dispatch(DELETE_MEMBER, {
          app_id: this.selectedApp,
          member_id: this.member.id,
        })
        .then((response) => {
          this.loadingConfirm = false;
          this.notifyUserMessage({
            message: response.message || "Successfully deleted the app",
          });
          this.removeMemberAppStats({ memberID: this.member.id, count: 1 });
          this.setSearchAppsInitial([]);
          this.removeDialog = false;
        })
        .catch((err) => {
          this.loadingConfirm = false;
          this.removeDialog = false;
          this.notifyErrorMessage(
            err.message || "Something went wrong, please try again"
          );
        });
    },
  },
};
</script>
