var render = function render(){
  var _vm$selectedMember, _vm$selectedMember$ro;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', [_c('div', [_vm._v("Transfer ownership")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-icon', {
    domProps: {
      "textContent": _vm._s('mdi-close')
    }
  })], 1)], 1), _c('v-card-text', [_c('v-row', [_vm.fromMemberList ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_vm._v(" Name: "), _c('b', [_vm._v(_vm._s(_vm.selectedMember.name))])]), _c('div', [_vm._v(" Current role: "), _c('b', [_vm._v(_vm._s((_vm$selectedMember = _vm.selectedMember) === null || _vm$selectedMember === void 0 ? void 0 : (_vm$selectedMember$ro = _vm$selectedMember.role) === null || _vm$selectedMember$ro === void 0 ? void 0 : _vm$selectedMember$ro.name))])])]) : _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('member-select', {
    attrs: {
      "app": null,
      "single": "",
      "from-transfer-ownership": ""
    },
    model: {
      value: _vm.selectedMemberFromSelect,
      callback: function callback($$v) {
        _vm.selectedMemberFromSelect = $$v;
      },
      expression: "selectedMemberFromSelect"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', _vm._b({
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'max:600',
      expression: "'max:600'"
    }],
    attrs: {
      "counter": "600",
      "filled": "",
      "auto-grow": ""
    },
    model: {
      value: _vm.transferMessage,
      callback: function callback($$v) {
        _vm.transferMessage = $$v;
      },
      expression: "transferMessage"
    }
  }, 'v-textarea', _vm.veeValidate('message', 'Message for Owner'), false))], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "disabled": _vm.loading,
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" Cancel ")]), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "outlined": _vm.$vuetify.theme.dark,
      "loading": _vm.loading,
      "color": "primary"
    },
    on: {
      "click": _vm.confirmTransferOwnership
    }
  }, [_vm._v(" Confirm ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }