<template>
  <div class="d-flex flex-column flex-root fill-height">
    <div class="d-flex flex-column fill-height">
      <div class="text-center mb-8 mt-5 display-1 bold-text">Devices</div>
      <div class="d-flex flex-column">
        <h1 v-if="appName" class="col-12 text-center">
          {{ appName }}
        </h1>
        <h3 v-if="member.name" class="col-12 text-center">
          {{ member.name }}
        </h3>
        <v-divider />
      </div>
      <div v-if="!release || member.status === 1" class="text-right mt-4">
        <v-btn
          :disabled="!showDownloadUDID"
          :loading="busy"
          @click="$emit('download-udid', 'fromDetailPage')"
          class="text-transform-none"
          color="primary"
          outlined
        >
          Download iOS UDIDs
        </v-btn>
        <v-btn
          small
          @click="$emit('download-udid-info', 'fromDetailPage')"
          icon
        >
          <v-icon size="20">mdi-help-circle-outline</v-icon>
        </v-btn>
      </div>
      <div v-if="loading">
        <v-card outlined class="radius-10 mt-8">
          <v-skeleton-loader
            v-for="i in 2"
            :key="i"
            type="list-item-three-line"
          />
        </v-card>
      </div>
      <div v-else class="d-flex flex-column flex-root overflow-hidden">
        <div v-if="devices.length" class="overflow-auto mt-8">
          <template v-if="release">
            <div class="user-device-list">
              <div
                v-for="(device, j) of devices"
                :key="j"
                class="user-device-info"
              >
                <div class="row">
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text">Name:</span>
                    {{ device.device.name }}
                  </div>
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text">OS:</span>
                    {{ device.device.os }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text">Model:</span>
                    {{ device.device.model }}
                  </div>
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text">Language:</span>
                    {{ device.device.language || device.device.locale || "-" }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text">Manufacturer:</span>
                    {{ device.device.manufacturer }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text">Notifications Enabled:</span>
                    {{
                      device?.device?.app?.push_enabled ||
                      device?.device?.push_enabled
                        ? "Yes"
                        : "No"
                    }}
                  </div>
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text">SDK version:</span>
                    {{ device.device.sdk_version }}
                  </div>
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text">OS version:</span>
                    <MaxText
                      v-if="device.device.os_version"
                      :text="device.device.os_version"
                      max="7"
                    />
                  </div>
                </div>
                <div class="py-1 px-3">
                  <span class="bold-text">UUID:</span>
                  {{ device.uuid || device?.device?.uuid || "-" }}
                </div>
                <div class="py-1 px-3" v-if="device.device.os == 'ios'">
                  <span class="bold-text">UDID:</span>
                  {{ device.udid || "-" }}
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="user-device-list">
              <div
                v-for="(device, j) of devices"
                :key="j"
                class="user-device-info"
              >
                <div class="row">
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text">OS:</span>
                    {{ device?.os || "-" }}
                  </div>
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text">Name:</span>
                    {{ device.info.name || "-" }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text">Model:</span>
                    {{ device.info.model || "-" }}
                  </div>
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text">Language:</span>
                    {{ device?.info?.language || device?.info?.locale || "-" }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text">Manufacturer:</span>
                    {{ device.info.manufacturer || "-" }}
                  </div>
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text">Type:</span>
                    {{ device.info.type || "-" }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text">Notifications Enabled:</span>
                    {{
                      device?.info?.app?.push_enabled ||
                      device?.info?.push_enabled
                        ? "Yes"
                        : "No"
                    }}
                  </div>
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text">SDK version:</span>
                    {{ device.info.sdk_version || "-" }}
                  </div>
                  <div class="col-6 d-flex justify-space-between py-1 px-3">
                    <span class="bold-text">OS version:</span>
                    <!-- check te docs for this component -->
                    <MaxText
                      v-if="device.info.os_version"
                      :text="device.info.os_version"
                      max="7"
                    />
                    <!-- {{ device.os_version }} -->
                  </div>
                </div>
                <div>
                  <br />
                  <span class="bold-text">UUID:</span>
                  {{ device.uuid || device.info.uuid || "-" }}
                  <v-btn
                    v-if="device.uuid || device.info.uuid"
                    @click="copy(device.uuid || device.info.uuid)"
                    icon
                  >
                    <v-icon size="16" v-text="'mdi-content-copy'"></v-icon>
                  </v-btn>
                </div>
                <div v-if="device.os == 'ios'">
                  <br />
                  <span class="bold-text">UDID:</span>
                  {{ device.udid || "-" }}
                  <v-btn v-if="device.udid" @click="copy(device.udid)" icon>
                    <v-icon size="16" v-text="'mdi-content-copy'"></v-icon>
                  </v-btn>
                </div>
                <br />
              </div>
            </div>
          </template>
        </div>

        <div v-else class="no-data-found">
          <inline-svg
            class="mt-6 svg-icon empty_icon"
            :src="`/media/placeholders/no-devices${
              $vuetify.theme.dark ? '-dark' : ''
            }.svg`"
          />
          <p class="mt-4 empty-title bold-text text-dark text-center">
            {{ member.status === 1 ? "No devices" : "Oops!" }}
          </p>
          <p
            class="mt-4 mb-5 empty-sub-title font-weight-normal text-dark text-center"
          >
            <span v-if="member.status === 1">
              Seems like this member haven't logged in in our app yet
            </span>
            <span v-else>
              The team member you're trying to view is currently deactivated. We
              can't provide more details unless they are active. Reach out to
              your administrator to get them reactivated.
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.user-device-list {
  padding: 10px;
  border: 1px solid rgba(57, 107, 219, 0.2);
  border-radius: 10px;
  margin-bottom: 10px;
}

.user-device-info {
  padding: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(57, 107, 219, 0.2);
  .title {
    font-family: "Open Sans Semi Bold";
    color: rgba(70, 78, 95, 0.6);
  }
}

.user-device-info:last-of-type {
  border-bottom: none;
}
</style>

<script>
import { copy } from "clipboard";

export default {
  name: "device-info",
  props: {
    busy: {
      type: Boolean,
      default: false,
    },
    app: {
      type: String,
      default: "",
    },
    appName: {
      type: String,
      default: "",
    },
    member: {
      type: Object,
      default() {
        return {};
      },
    },
    devices: {
      type: [Object, Array],
      default() {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    release: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showDownloadUDID() {
      if (
        this.devices &&
        this.devices.length &&
        this.devices.some((x) => !!x.udid)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
