var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column flex-root fill-height"
  }, [_c('div', {
    staticClass: "d-flex flex-column fill-height"
  }, [_c('div', {
    staticClass: "text-center mb-8 mt-5 display-1 bold-text"
  }, [_vm._v("Devices")]), _c('div', {
    staticClass: "d-flex flex-column"
  }, [_vm.appName ? _c('h1', {
    staticClass: "col-12 text-center"
  }, [_vm._v(" " + _vm._s(_vm.appName) + " ")]) : _vm._e(), _vm.member.name ? _c('h3', {
    staticClass: "col-12 text-center"
  }, [_vm._v(" " + _vm._s(_vm.member.name) + " ")]) : _vm._e(), _c('v-divider')], 1), !_vm.release || _vm.member.status === 1 ? _c('div', {
    staticClass: "text-right mt-4"
  }, [_c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "disabled": !_vm.showDownloadUDID,
      "loading": _vm.busy,
      "color": "primary",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('download-udid', 'fromDetailPage');
      }
    }
  }, [_vm._v(" Download iOS UDIDs ")]), _c('v-btn', {
    attrs: {
      "small": "",
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('download-udid-info', 'fromDetailPage');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "20"
    }
  }, [_vm._v("mdi-help-circle-outline")])], 1)], 1) : _vm._e(), _vm.loading ? _c('div', [_c('v-card', {
    staticClass: "radius-10 mt-8",
    attrs: {
      "outlined": ""
    }
  }, _vm._l(2, function (i) {
    return _c('v-skeleton-loader', {
      key: i,
      attrs: {
        "type": "list-item-three-line"
      }
    });
  }), 1)], 1) : _c('div', {
    staticClass: "d-flex flex-column flex-root overflow-hidden"
  }, [_vm.devices.length ? _c('div', {
    staticClass: "overflow-auto mt-8"
  }, [_vm.release ? [_c('div', {
    staticClass: "user-device-list"
  }, _vm._l(_vm.devices, function (device, j) {
    var _device$device, _device$device$app, _device$device2, _device$device3;
    return _c('div', {
      key: j,
      staticClass: "user-device-info"
    }, [_c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col-6 d-flex justify-space-between py-1 px-3"
    }, [_c('span', {
      staticClass: "bold-text"
    }, [_vm._v("Name:")]), _vm._v(" " + _vm._s(device.device.name) + " ")]), _c('div', {
      staticClass: "col-6 d-flex justify-space-between py-1 px-3"
    }, [_c('span', {
      staticClass: "bold-text"
    }, [_vm._v("OS:")]), _vm._v(" " + _vm._s(device.device.os) + " ")])]), _c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col-6 d-flex justify-space-between py-1 px-3"
    }, [_c('span', {
      staticClass: "bold-text"
    }, [_vm._v("Model:")]), _vm._v(" " + _vm._s(device.device.model) + " ")]), _c('div', {
      staticClass: "col-6 d-flex justify-space-between py-1 px-3"
    }, [_c('span', {
      staticClass: "bold-text"
    }, [_vm._v("Language:")]), _vm._v(" " + _vm._s(device.device.language || device.device.locale || "-") + " ")])]), _c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col-6 d-flex justify-space-between py-1 px-3"
    }, [_c('span', {
      staticClass: "bold-text"
    }, [_vm._v("Manufacturer:")]), _vm._v(" " + _vm._s(device.device.manufacturer) + " ")])]), _c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col-6 d-flex justify-space-between py-1 px-3"
    }, [_c('span', {
      staticClass: "bold-text"
    }, [_vm._v("Notifications Enabled:")]), _vm._v(" " + _vm._s(device !== null && device !== void 0 && (_device$device = device.device) !== null && _device$device !== void 0 && (_device$device$app = _device$device.app) !== null && _device$device$app !== void 0 && _device$device$app.push_enabled || device !== null && device !== void 0 && (_device$device2 = device.device) !== null && _device$device2 !== void 0 && _device$device2.push_enabled ? "Yes" : "No") + " ")]), _c('div', {
      staticClass: "col-6 d-flex justify-space-between py-1 px-3"
    }, [_c('span', {
      staticClass: "bold-text"
    }, [_vm._v("SDK version:")]), _vm._v(" " + _vm._s(device.device.sdk_version) + " ")]), _c('div', {
      staticClass: "col-6 d-flex justify-space-between py-1 px-3"
    }, [_c('span', {
      staticClass: "bold-text"
    }, [_vm._v("OS version:")]), device.device.os_version ? _c('MaxText', {
      attrs: {
        "text": device.device.os_version,
        "max": "7"
      }
    }) : _vm._e()], 1)]), _c('div', {
      staticClass: "py-1 px-3"
    }, [_c('span', {
      staticClass: "bold-text"
    }, [_vm._v("UUID:")]), _vm._v(" " + _vm._s(device.uuid || (device === null || device === void 0 ? void 0 : (_device$device3 = device.device) === null || _device$device3 === void 0 ? void 0 : _device$device3.uuid) || "-") + " ")]), device.device.os == 'ios' ? _c('div', {
      staticClass: "py-1 px-3"
    }, [_c('span', {
      staticClass: "bold-text"
    }, [_vm._v("UDID:")]), _vm._v(" " + _vm._s(device.udid || "-") + " ")]) : _vm._e()]);
  }), 0)] : [_c('div', {
    staticClass: "user-device-list"
  }, _vm._l(_vm.devices, function (device, j) {
    var _device$info, _device$info2, _device$info3, _device$info3$app, _device$info4;
    return _c('div', {
      key: j,
      staticClass: "user-device-info"
    }, [_c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col-6 d-flex justify-space-between py-1 px-3"
    }, [_c('span', {
      staticClass: "bold-text"
    }, [_vm._v("OS:")]), _vm._v(" " + _vm._s((device === null || device === void 0 ? void 0 : device.os) || "-") + " ")]), _c('div', {
      staticClass: "col-6 d-flex justify-space-between py-1 px-3"
    }, [_c('span', {
      staticClass: "bold-text"
    }, [_vm._v("Name:")]), _vm._v(" " + _vm._s(device.info.name || "-") + " ")])]), _c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col-6 d-flex justify-space-between py-1 px-3"
    }, [_c('span', {
      staticClass: "bold-text"
    }, [_vm._v("Model:")]), _vm._v(" " + _vm._s(device.info.model || "-") + " ")]), _c('div', {
      staticClass: "col-6 d-flex justify-space-between py-1 px-3"
    }, [_c('span', {
      staticClass: "bold-text"
    }, [_vm._v("Language:")]), _vm._v(" " + _vm._s((device === null || device === void 0 ? void 0 : (_device$info = device.info) === null || _device$info === void 0 ? void 0 : _device$info.language) || (device === null || device === void 0 ? void 0 : (_device$info2 = device.info) === null || _device$info2 === void 0 ? void 0 : _device$info2.locale) || "-") + " ")])]), _c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col-6 d-flex justify-space-between py-1 px-3"
    }, [_c('span', {
      staticClass: "bold-text"
    }, [_vm._v("Manufacturer:")]), _vm._v(" " + _vm._s(device.info.manufacturer || "-") + " ")]), _c('div', {
      staticClass: "col-6 d-flex justify-space-between py-1 px-3"
    }, [_c('span', {
      staticClass: "bold-text"
    }, [_vm._v("Type:")]), _vm._v(" " + _vm._s(device.info.type || "-") + " ")])]), _c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col-6 d-flex justify-space-between py-1 px-3"
    }, [_c('span', {
      staticClass: "bold-text"
    }, [_vm._v("Notifications Enabled:")]), _vm._v(" " + _vm._s(device !== null && device !== void 0 && (_device$info3 = device.info) !== null && _device$info3 !== void 0 && (_device$info3$app = _device$info3.app) !== null && _device$info3$app !== void 0 && _device$info3$app.push_enabled || device !== null && device !== void 0 && (_device$info4 = device.info) !== null && _device$info4 !== void 0 && _device$info4.push_enabled ? "Yes" : "No") + " ")]), _c('div', {
      staticClass: "col-6 d-flex justify-space-between py-1 px-3"
    }, [_c('span', {
      staticClass: "bold-text"
    }, [_vm._v("SDK version:")]), _vm._v(" " + _vm._s(device.info.sdk_version || "-") + " ")]), _c('div', {
      staticClass: "col-6 d-flex justify-space-between py-1 px-3"
    }, [_c('span', {
      staticClass: "bold-text"
    }, [_vm._v("OS version:")]), device.info.os_version ? _c('MaxText', {
      attrs: {
        "text": device.info.os_version,
        "max": "7"
      }
    }) : _vm._e()], 1)]), _c('div', [_c('br'), _c('span', {
      staticClass: "bold-text"
    }, [_vm._v("UUID:")]), _vm._v(" " + _vm._s(device.uuid || device.info.uuid || "-") + " "), device.uuid || device.info.uuid ? _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.copy(device.uuid || device.info.uuid);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "size": "16"
      },
      domProps: {
        "textContent": _vm._s('mdi-content-copy')
      }
    })], 1) : _vm._e()], 1), device.os == 'ios' ? _c('div', [_c('br'), _c('span', {
      staticClass: "bold-text"
    }, [_vm._v("UDID:")]), _vm._v(" " + _vm._s(device.udid || "-") + " "), device.udid ? _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.copy(device.udid);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "size": "16"
      },
      domProps: {
        "textContent": _vm._s('mdi-content-copy')
      }
    })], 1) : _vm._e()], 1) : _vm._e(), _c('br')]);
  }), 0)]], 2) : _c('div', {
    staticClass: "no-data-found"
  }, [_c('inline-svg', {
    staticClass: "mt-6 svg-icon empty_icon",
    attrs: {
      "src": "/media/placeholders/no-devices".concat(_vm.$vuetify.theme.dark ? '-dark' : '', ".svg")
    }
  }), _c('p', {
    staticClass: "mt-4 empty-title bold-text text-dark text-center"
  }, [_vm._v(" " + _vm._s(_vm.member.status === 1 ? "No devices" : "Oops!") + " ")]), _c('p', {
    staticClass: "mt-4 mb-5 empty-sub-title font-weight-normal text-dark text-center"
  }, [_vm.member.status === 1 ? _c('span', [_vm._v(" Seems like this member haven't logged in in our app yet ")]) : _c('span', [_vm._v(" The team member you're trying to view is currently deactivated. We can't provide more details unless they are active. Reach out to your administrator to get them reactivated. ")])])], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }